import React from "react"
import Hero from "../components/hero-sf"
import SFDetails from "../components/salesforce-details"

import "../assets/index.css"
import Layout from "../layouts/layout"


export default () => (
    <Layout title="Salesforce | Ladd Partners"> 
        <Hero/>
        <SFDetails/>
    </Layout>
)
